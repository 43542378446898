<template>
  <div class="public">
    <div class="container HomeTrade">
      <!-- 四色码 -->
      <div class="idiot-one">
        <div class="box" v-for="(item, i) in forCodeData" :key="i">
          <div class="box-header">
            <span>{{
                item.type == 1
                    ? "交易笔数"
                    : item.type == 2
                    ? "交易金额"
                    : item.type == 3
                        ? "兑付金额"
                        : item.type == 4
                            ? "兑付比"
                            : ""
              }}</span>
            <span v-if="item.type == 1 || item.type == 4">{{ item.num }}</span>
            <span v-else>{{ item.num | numFilter }}</span>
            {{
              item.type == 1
                  ? "笔"
                  : item.type == 2
                  ? "元"
                  : item.type == 3
                      ? "元"
                      : item.type == 4
                          ? ""
                          : ""
            }}
          </div>
          <div class="box-one">
            <span>较昨日：</span>
            <span v-if="item.type == 1 || item.type == 4">{{
                item.yesterdayNum
              }}</span>
            <span v-else>{{ item.yesterdayNum | numFilter }}</span>
            <span
            ><i
                :class="
                  (item.yesterdayNum + '').replace('%','') > 0
                    ? 'el-icon-top t'
                    : (item.yesterdayNum + '').replace('%','') < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
            ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上周：</span>
            <span v-if="item.type == 1 || item.type == 4">{{
                item.weekNum
              }}</span>
            <span v-else>{{ item.weekNum | numFilter }}</span>
            <span
            ><i
                :class="
                  (item.weekNum + '').replace('%','')  > 0
                    ? 'el-icon-top t'
                    : (item.weekNum + '').replace('%','') < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
            ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上月：</span>
            <span v-if="item.type == 1 || item.type == 4">{{
                item.monthNum
              }}</span>
            <span v-else>{{ item.monthNum | numFilter }}</span>
            <span
            ><i
                :class="
                  (item.monthNum + '').replace('%','') > 0
                    ? 'el-icon-top t'
                    : (item.monthNum + '').replace('%','')  < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
            ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上年：</span>
            <span v-if="item.type == 1 || item.type == 4">{{
                item.yearNum
              }}</span>
            <span v-else>{{ item.yearNum | numFilter }}</span>
            <span
            ><i
                :class="
                  (item.yearNum + '').replace('%','') > 0
                    ? 'el-icon-top t'
                    : (item.yearNum + '').replace('%','') < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
            ></i
            ></span>
          </div>
        </div>
      </div>
      <!-- 商户区域分布情况 中国地图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>预付交易区域分布图</span>
          </div>
        </div>

        <div class="map-charts">
          <Baidumap
              v-loading="showbmap"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(9,32,57, 0.1)"
              v-if="regionType >= 3 && areaName"
              :regionName="regionName"
              :flag="flag"
              :cityName="cityName"
              :areaName="areaName"
              :data="baidumapOptions"
          />
          <Pmap
              v-else
              v-loading="showmap"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(9,32,57, 0.1)"
              :regionName="regionName"
              :cityName="cityName"
              :flag="flag"
              :mapOptions="mapOptions"
          ></Pmap>
        </div>
      </div>
      <!-- 预付码新增变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>预付交易新增变化趋势 </span>
          </div>
          <el-radio-group v-model="tabPosition" class="tab-select-btn">
            <el-radio-button label="1">交易笔数</el-radio-button>
            <el-radio-button label="2">交易金额</el-radio-button>
            <el-radio-button label="3">兑付金额</el-radio-button>
            <el-radio-button label="4">兑付比</el-radio-button>
          </el-radio-group>
          <div class="echarts-but">
            <el-radio-group v-model="radio1">
              <el-radio-button
                  v-for="item in radioData1"
                  :label="item.value"
                  :key="item.value"
              >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>
        <div class="charts">
          <LineChart
              :chartData="lineChartData1"
              v-loading="lineChart1"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              :legendData="[]"
              :units="units1"
              :yAxis="yAxis1"
              :yAxisIndex="[0]"
              :type="radio1"
          ></LineChart>
        </div>
      </div>
      <!-- 预付码累计变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>预付交易累计变化趋势</span>
          </div>
          <el-radio-group v-model="tabPosition2" class="tab-select-btn">
            <el-radio-button label="1">交易笔数</el-radio-button>
            <el-radio-button label="2">交易金额</el-radio-button>
            <el-radio-button label="3">兑付金额</el-radio-button>
            <el-radio-button label="4">兑付比</el-radio-button>
          </el-radio-group>
          <div class="echarts-but">
            <el-radio-group v-model="radio2">
              <el-radio-button
                  v-for="item in radioData1"
                  :label="item.value"
                  :key="item.value"
              >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>
        <div class="charts">
          <LineChart
              :chartData="lineChartData2"
              v-loading="lineChart2"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              :legendData="[]"
              :units="units2"
              :yAxis="yAxis2"
              :yAxisIndex="[0]"
              :type="radio2"
          ></LineChart>
        </div>
      </div>
      <!-- 预付码按城市/地区统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>预付交易区域分布数量统计</span>
          </div>
          <div class="h-selct">
            <el-select v-model="hairpinType1" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                  v-for="item in options"
                  :key="item.haripinType"
                  :label="item.name"
                  :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="element-tab">
          <el-table
              :data="tableDataAll"
              v-loading="loading1"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              style="width: 100%"
              :row-key="rowKey"
              lazy
              :load="loadNextNode1"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              @sort-change="changeSort"
          >
            <el-table-column
                prop="regionName"
                label="地区"
                sortable
                width="290"
            ></el-table-column>
            <el-table-column prop="num" label="笔数" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num }}</span
                ><i
                  :class="
                    scope.row.numSign > 0
                      ? 'num-badge-blue'
                      : scope.row.numSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.numSign > 0
                      ? "+" + scope.row.numSign
                      : scope.row.numSign < 0
                      ? "" + scope.row.numSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="cardMoney" label="发卡金额" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.cardMoney }}</span
                ><i
                  :class="
                    scope.row.cardMoneySign > 0
                      ? 'num-badge-blue'
                      : scope.row.cardMoneySign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.cardMoneySign > 0
                      ? "+" + scope.row.cardMoneySign
                      : scope.row.cardMoneySign < 0
                      ? "" + scope.row.cardMoneySign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="unMoney" label="兑付金额" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.unMoney }}</span
                ><i
                  :class="
                    scope.row.unMoneySign > 0
                      ? 'num-badge-blue'
                      : scope.row.unMoneySign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.unMoneySign > 0
                      ? "+" + scope.row.unMoneySign
                      : scope.row.unMoneySign < 0
                      ? "" + scope.row.unMoneySign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="ratio" label="兑付比" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.ratio }}%</span
                ><i
                  :class="
                    scope.row.ratioSign > 0
                      ? 'num-badge-blue'
                      : scope.row.ratioSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.ratioSign > 0
                      ? "+" + scope.row.ratioSign + "%"
                      : scope.row.ratioSign < 0
                      ? "" + scope.row.ratioSign + "%"
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
          </el-table>
          <div class="page-btn" v-if="tableData1.length > 10">
            <el-button type="text" @click="showAll = !showAll"
            >{{ showAll ? "收起全部" : "展开全部"
              }}<i
                  class="el-icon-d-arrow-left el-icon--right"
                  :class="showAll ? 'rotate-top' : 'rotate-bottom'"
              ></i
              ></el-button>
          </div>
        </div>
      </div>
      <!-- 预付码按领域/行业统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>预付交易领域/行业分布数量统计</span>
          </div>
          <div class="h-selct">
            <el-select v-model="hairpinType2" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                  v-for="item in options"
                  :key="item.haripinType"
                  :label="item.name"
                  :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="element-tab">
          <el-table
              :data="tableDataAll2"
              v-loading="loading2"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              style="width: 100%"
              row-key="industryId"
              lazy
              :load="loadNextNode2"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              @sort-change="changeSort2"
          >
            <el-table-column prop="industryName" label="领域/行业" sortable>
            </el-table-column>
            <el-table-column prop="num" label="笔数" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num }}</span
                ><i
                  :class="
                    scope.row.numSign > 0
                      ? 'num-badge-blue'
                      : scope.row.numSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.numSign > 0
                      ? "+" + scope.row.numSign
                      : scope.row.numSign < 0
                      ? "" + scope.row.numSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="cardMoney" label="发卡金额" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{
                    scope.row.cardMoney | numFilter
                  }}</span
                ><i
                  :class="
                    scope.row.cardMoneySign > 0
                      ? 'num-badge-blue'
                      : scope.row.cardMoneySign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.cardMoneySign > 0
                      ? "+" + scope.row.cardMoneySign
                      : scope.row.cardMoneySign < 0
                      ? "" + scope.row.cardMoneySign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="unMoney" label="兑付金额" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.unMoney | numFilter }}</span
                ><i
                  :class="
                    scope.row.unMoneySign > 0
                      ? 'num-badge-blue'
                      : scope.row.unMoneySign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.unMoneySign > 0
                      ? "+" + scope.row.unMoneySign
                      : scope.row.unMoneySign < 0
                      ? "" + scope.row.unMoneySign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="ratio" label="兑付比" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.ratio }}%</span
                ><i
                  :class="
                    scope.row.ratioSign > 0
                      ? 'num-badge-blue'
                      : scope.row.ratioSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.ratioSign > 0
                      ? "+" + scope.row.ratioSign + "%"
                      : scope.row.ratioSign < 0
                      ? "" + scope.row.ratioSign + "%"
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
          </el-table>
          <div class="page-btn" v-if="tableData2.length > 10">
            <el-button type="text" @click="showAll2 = !showAll2"
            >{{ showAll2 ? "收起全部" : "展开全部"
              }}<i
                  class="el-icon-d-arrow-left el-icon--right"
                  :class="showAll2 ? 'rotate-top' : 'rotate-bottom'"
              ></i
              ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import LineChart from "@/components/charts/line2"; // 四码折线图
import Pmap from "@/components/charts/map"; //地图
import {
  transactionInfo,
  cashingStatistics,
  transactionAdd,
  transactionCumulative,
  transactionCityStatistics,
  transactionIndustryStatistics,
} from "@/api/hometrade";
import { cardType } from "@/api/common";
import Baidumap from "@/components/baidumap/index.vue";
export default {
  name: "HomeTrade",
  props: {
    regionName: [String],
    areaName:[String],
    cityName: [String],
    flag:[String, Number],
    regionId: [String, Number],
    regionType: [String, Number],
  },
  data() {
    return {
      showmap: true,
      showbmap: true,
      lineChart1: false,
      lineChart2: false,
      hairpinType1: "",
      hairpinType2: "",
      options: [],
      radio1: "1",
      radio2: "1",
      radioData1: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      lineChartData1: {
        xAxisData: [],
        seriesData: [
          {
            name: "交易笔数",
            data: [],
          },
        ],
      },
      lineChartData2: {
        xAxisData: [],
        seriesData: [
          {
            name: "不发卡",
            data: [],
          },
          {
            name: "红码",
            data: [],
          },
          {
            name: "黄码",
            data: [],
          },
          {
            name: "绿码",
            data: [],
          },
        ],
      },
      baidumapOptions: [],
      showAll: false,
      loading1: false,
      loading2: false,
      tableDataAll: [],
      tableData1: [],
      showAll2: false,
      tableDataAll2: [],
      tableData2: [],
      mapOptions: [],
      forCodeData: [],
      tabPosition: "1",
      tabPosition2: "1",
      units1: [""],
      units2: [""],
      yAxis1: [""],
      yAxis2: [""],
    };
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2);
      return realVal;
    },
  },
  computed: {
    costRequest() {
      return {
        regionName: this.regionName,
        cityName: this.cityName,
        areaName:this.areaName,
        flag: this.flag,
        regionId: this.regionId,
        regionType: this.regionType,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        console.log("HomeTrade.vue",val)
        this.intData();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    radio1: function (val) {
      this.transactionAdd();
    },
    tabPosition: function (val) {
      this.transactionAdd();
    },
    radio2: function (val) {
      this.transactionCumulative();
    },
    tabPosition2: function (val) {
      this.transactionCumulative();
    },
    hairpinType1: function (val) {
      this.transactionCityStatistics();
    },
    hairpinType2: function (val) {
      this.transactionIndustryStatistics();
    },
    showAll: function (val) {
      this.showAllData();
    },
    showAll2: function (val) {
      this.showAllData2();
    },
  },
  mounted() {
    if (sessionStorage.getItem("initParams") === "init") {
      this.intData();
    }
  },
  beforeDestroy() {
    window.sessionStorage.setItem("initParams", "init");
  },
  methods: {
    intData() {
      this.cardType();
      this.getMapOptionData();
      this.cashingStatistics();
      this.transactionAdd();
      this.transactionCumulative();
      this.transactionCityStatistics();
      this.transactionIndustryStatistics();
    },
    cardType() {
      cardType().then((res) => {
        this.options = res.data;
      });
    },
    getMapOptionData() {
      this.showbmap=true;
      this.showmap=true;
      transactionInfo({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag
      })
          .then((res) => {
            //console.log('预付卡交易分布情况:',res)
            let data = [];
            res.data.forEach((item) => {
              data.push({
                name: item.regionName,
                value: item.num,
                money: item.money,
                ratio: item.ratio,
              });
            });
            this.showmap = false;
            this.mapOptions = data;
            if (this.regionType >= 3 && this.areaName) {
              this.baidumapOptions = data;
              this.showbmap = false;
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    cashingStatistics() {
      cashingStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag
      })
          .then((res) => {
            // console.log('交易兑付情况统计:',res.data)
            this.forCodeData = res.data;

          })
          .catch((err) => {
            console.log(err);
          });
    },
    transactionAdd() {
      let _this = this;
      this.lineChart1 = true;
      transactionAdd({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio1,
      }).then((res) => {
        //console.log('预付交易新增变化趋势',res)
        this.lineChart1 = false;
        if (res.data != null) {
          this.lineChartData1 = {
            xAxisData: res.data.x_axis,
            seriesData: [
              {
                name:
                    _this.tabPosition == 1
                        ? "交易笔数"
                        : _this.tabPosition == 2
                        ? "交易金额"
                        : _this.tabPosition == 3
                            ? "兑付金额"
                            : _this.tabPosition == 4
                                ? "兑付比"
                                : "交易笔数",
                data:
                    _this.tabPosition == 1
                        ? res.data.num
                        : _this.tabPosition == 2
                        ? res.data.cashing
                        : _this.tabPosition == 3
                            ? res.data.unCashing
                            : _this.tabPosition == 4
                                ? res.data.ratio
                                : [],
              },
            ],
          };
        } else {
          this.lineChartData1 = {
            xAxisData: [],
            seriesData: [
              {
                name:
                    _this.tabPosition == 1
                        ? "交易笔数"
                        : _this.tabPosition == 2
                        ? "交易金额"
                        : _this.tabPosition == 3
                            ? "兑付金额"
                            : _this.tabPosition == 4
                                ? "兑付比"
                                : "交易笔数",
                data: [],
              },
            ],
          };
        }
        _this.units1 =
            _this.tabPosition == 1
                ? ["笔"]
                : _this.tabPosition == 2
                ? ["元"]
                : _this.tabPosition == 3
                    ? ["元"]
                    : _this.tabPosition == 4
                        ? ["%"]
                        : ["笔"];
        _this.yAxis1 = _this.tabPosition == 4 ? ["%"] : [""];
      });
    },
    transactionCumulative() {
      let _this = this;
      this.lineChart2 = true;
      transactionCumulative({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio2,
      }).then((res) => {
        //console.log('预付交易累计变化趋势',res)
        this.lineChart2 = false;
        if (res.data != null) {
          this.lineChartData2 = {
            xAxisData: res.data.x_axis,
            seriesData: [
              {
                name:
                    _this.tabPosition2 == 1
                        ? "交易笔数"
                        : _this.tabPosition2 == 2
                        ? "交易金额"
                        : _this.tabPosition2 == 3
                            ? "兑付金额"
                            : _this.tabPosition2 == 4
                                ? "兑付比"
                                : "交易笔数",
                data:
                    _this.tabPosition2 == 1
                        ? res.data.num
                        : _this.tabPosition2 == 2
                        ? res.data.cashing
                        : _this.tabPosition2 == 3
                            ? res.data.unCashing
                            : _this.tabPosition2 == 4
                                ? res.data.ratio
                                : [],
              },
            ],
          };
        } else {
          this.lineChartData2 = {
            xAxisData: [],
            seriesData: [
              {
                name:
                    _this.tabPosition2 == 1
                        ? "交易笔数"
                        : _this.tabPosition2 == 2
                        ? "交易金额"
                        : _this.tabPosition2 == 3
                            ? "兑付金额"
                            : _this.tabPosition2 == 4
                                ? "兑付比"
                                : "交易笔数",
                data: [],
              },
            ],
          };
        }
        _this.units2 =
            _this.tabPosition2 == 1
                ? ["笔"]
                : _this.tabPosition2 == 2
                ? ["元"]
                : _this.tabPosition2 == 3
                    ? ["元"]
                    : _this.tabPosition2 == 4
                        ? ["%"]
                        : ["笔"];
        _this.yAxis2 = _this.tabPosition2 == 4 ? ["%"] : [""];
      });
    },
    transactionCityStatistics() {
      this.loading1 = true;
      transactionCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag
      }).then((res) => {
        //console.log('预付码按城市/地区统计',res)
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        this.loading1 = false;
        this.tableDataAll = [];
        this.$nextTick(() => {
          this.tableData1 = res.data || [];
          this.tableDataAll = this.tableData1.slice(0, 10);
          this.showAllData();
        });
      });
    },
    showAllData() {
      if (!this.showAll) {
        this.tableDataAll = this.tableData1.slice(0, 10);
      } else {
        this.tableDataAll = this.tableData1;
      }
    },
    rowKey(row) {
      return row.regionName + row.regionId;
    },
    loadNextNode1(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      transactionCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: tree.regionId,
        regionType: tree.regionType,
        flag: tree.regionType
      }).then((res) => {
        //console.log('预付码按城市/地区统计-二级',res)
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        resolve(res.data || []);
      });
    },
    transactionIndustryStatistics() {
      this.loading2 = true;
      transactionIndustryStatistics({
        hairpinType: this.hairpinType2,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag
      }).then((res) => {
        //console.log('预付码按领域/行业统计',res)
        res.data.forEach((item) => {
          item.hasChildren = true;
        });
        this.loading2 = false;
        this.tableDataAll2 = [];
        this.$nextTick(() => {
          this.tableData2 = res.data || [];
          this.tableDataAll2 = this.tableData2.slice(0, 10);
          this.showAllData2();
        });
      });
    },
    showAllData2() {
      if (!this.showAll2) {
        this.tableDataAll2 = this.tableData2.slice(0, 10);
      } else {
        this.tableDataAll2 = this.tableData2;
      }
    },
    loadNextNode2(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      transactionIndustryStatistics({
        hairpinType: this.hairpinType2,
        industryId: tree.industryId,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag
      }).then((res) => {
        //console.log('预付码按领域/行业统计-二级',res)
        resolve(res.data);
      });
    },
    changeSort(column, prop, order) {
      this.tableDataAll = this.tableData1;
      this.showAll = true;
    },
    changeSort2(column, prop, order) {
      this.tableDataAll2 = this.tableData2;
      this.showAll2 = true;
    },
  },
  components: {
    LineChart,
    Pmap,
    Baidumap,
  },
};
</script>

<style lang="scss" scoped >
.HomeTrade {
  // 四色码
  .idiot-one {
    display: flex;
    flex-wrap: wrap;
    height: 240px;
    margin: 0 30px 30px 30px;
    border: 1px solid #17688d;
    background: linear-gradient(
            to bottom,
            rgba(43, 202, 255, 0.1),
            #0000,
            rgba(43, 202, 255, 0.1)
    );
    .box {
      width: 24.7%;
      text-align: center;
      .box-header {
        color: oldlace;
        margin-top: 30px;
        margin-bottom: 20px;
        font-size: 14px;
        span:nth-child(1) {
          font-size: 16px;
        }
        span:nth-child(2) {
          font-size: 24px;
          margin: 0 5px;
        }
      }
      .box-one {
        display: flex;
        justify-content: center;
        font-size: 14px;
        line-height: 32px;
        span:nth-child(1) {
          color: #9fa1a7;
        }
        span:nth-child(2) {
          color: #eee;
          width: 80px;
          text-align: left;
          margin-right: 5px;
        }
        span:nth-child(3) {
          color: #eee;
          width: 10px;
          font-size: 16px;
          .t {
            color: #ffba00;
            font-weight: bold;
          }
          .b {
            color: #59d2f3;
            font-weight: bold;
          }
        }
      }
    }
    .box:nth-child(1) {
      border-right: 1px solid #ddd;
      border-image: linear-gradient(
              #0000,
              #0000,
              #17688d,
              #17688d,
              #0000,
              #0000
      )
      30 30;
    }
    .box:nth-child(2) {
      border-right: 1px solid #ddd;
      border-image: linear-gradient(
              #0000,
              #0000,
              #17688d,
              #17688d,
              #0000,
              #0000
      )
      30 30;
      .box-header {
        span:nth-child(2) {
          color: #ff4646;
        }
      }
    }
    .box:nth-child(3) {
      border-right: 1px solid #ddd;
      border-image: linear-gradient(
              #0000,
              #0000,
              #17688d,
              #17688d,
              #0000,
              #0000
      )
      30 30;
      .box-header {
        span:nth-child(2) {
          color: #fecb01;
        }
      }
    }
    .box:nth-child(4) {
      .box-header {
        span:nth-child(2) {
          color: #49e71f;
        }
      }
    }
  }
  .tab-select-btn {
    position: absolute;
    top: 6px;
    right: 0;
    &.right-size {
      right: 120px;
    }
  }
}
</style>
